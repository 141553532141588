import { gql } from 'graphql-tag';
import ButtonBlockFragment from '@fragments/shared/ButtonBlockFragment';
import ContentCardBlockFragment from '@fragments/shared/ContentCardBlockFragment';
import EventPanelBlockFragment from '@fragments/shared/EventPanelBlockFragment';
import GalleryBlockFragment from '@fragments/shared/GalleryBlockFragment';
import HtmlBlockFragment from '@fragments/shared/HtmlBlockFragment';
import ImageBlockFragment from '@fragments/shared/ImageBlockFragment';
import PersonBlockFragment from '@fragments/shared/PersonBlockFragment';
import PullQuoteBlockFragment from '@fragments/shared/PullQuoteBlockFragment';
import RichTextBlockFragment from '@fragments/shared/RichTextBlockFragment';
import SubscribeBlockFragment from '@fragments/shared/SubscribeBlockFragment';
import VideoBlockFragment from '@fragments/shared/VideoBlockFragment';

export default gql`
    fragment StreamContainedFragment on SchemaHelper_StreamContained {
        ...ButtonBlockFragment
        ...ContentCardBlockFragment
        ...EventPanelBlockFragment
        ...GalleryBlockFragment
        ...HtmlBlockFragment
        ...ImageBlockFragment
        ...PersonBlockFragment
        ...PullQuoteBlockFragment
        ...RichTextBlockFragment
        ...SubscribeBlockFragment
        ...VideoBlockFragment
    }
    ${ButtonBlockFragment}
    ${ContentCardBlockFragment}
    ${EventPanelBlockFragment}
    ${GalleryBlockFragment}
    ${HtmlBlockFragment}
    ${ImageBlockFragment}
    ${PersonBlockFragment}
    ${PullQuoteBlockFragment}
    ${RichTextBlockFragment}
    ${SubscribeBlockFragment}
    ${VideoBlockFragment}
`;
