import { gql } from 'graphql-tag';
import ImageSummaryFragment from '@fragments/summaries/ImageSummaryFragment';

export default gql`
    fragment BlogSummaryFragment on Blog {
        path
        title
        dek
        heroBlog {
            heroImage {
                ...ImageSummaryFragment
            }
            mobileHeroImage {
                ...ImageSummaryFragment
            }
            logo {
                ...ImageSummaryFragment
            }
        }
    }
    ${ImageSummaryFragment}
`;
