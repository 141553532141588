import { gql } from 'graphql-tag';
import BlogSummaryFragment from '@fragments/summaries/BlogSummaryFragment';
import ProgramsSummaryFragment from '@fragments/summaries/ProgramsSummaryFragment';
import ProjectsSummaryFragment from '@fragments/summaries/ProjectsSummaryFragment';

export default gql`
    fragment SyndicationsFragment on Syndications {
        centers {
            global {
                syndicated
                isPrimary
                topics {
                    title
                    path
                }
                regions {
                    title
                    path
                }
            }
            middleEast {
                syndicated
                isPrimary
                topics {
                    title
                    path
                }
                regions {
                    title
                    path
                }
            }
            russiaEurasia {
                syndicated
                isPrimary
                topics {
                    title
                    path
                }
                regions {
                    title
                    path
                }
            }
            india {
                syndicated
                isPrimary
                topics {
                    title
                    path
                }
                regions {
                    title
                    path
                }
            }
            china {
                syndicated
                isPrimary
                topics {
                    title
                    path
                }
                regions {
                    title
                    path
                }
            }
            europe {
                syndicated
                isPrimary
                topics {
                    title
                    path
                }
                regions {
                    title
                    path
                }
            }
        }
        programs {
            reference {
                ...ProgramsSummaryFragment
            }
            isPrimary
        }
        projects {
            reference {
                ...ProjectsSummaryFragment
            }
            isPrimary
        }
        blogs {
            reference {
                ...BlogSummaryFragment
            }
            isPrimary
        }
        collections {
            title
            path
        }
        eventCollections {
            title
            path
        }
    }

    ${BlogSummaryFragment}
    ${ProgramsSummaryFragment}
    ${ProjectsSummaryFragment}
`;
